
import { Options, Vue } from 'vue-class-component'
import MswHeader from '@/components/Header.vue'
import MswFooter from '@/components/Footer.vue'

@Options({
  components: {
    MswHeader, MswFooter
  }
})
export default class Contact extends Vue {
}
