
import { Options, Vue } from 'vue-class-component'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline'
import SpinnerIcon from '@/components/SpinnerIcon.vue'

export enum State {
  Running,
  Succeeded,
  Failed
}

export type StepProps = {
  state: State,
  description: string,
  onSuccessDescription: string,
  onFailureDescription: string
}

@Options({
  props: {
    description: String,
    onSuccessDescription: String,
    onFailureDescription: String,
    state: State
  },
  components: { CheckCircleIcon, XCircleIcon, SpinnerIcon }
})
export default class MswStep extends Vue {
  description = '<step description missing>'
  state = State.Running

  get running(): boolean {
    return this.state === State.Running
  }

  get succeeded(): boolean {
    return this.state === State.Succeeded
  }

  get failed(): boolean {
    return this.state === State.Failed
  }
}

