
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    smFullscreen: Boolean
  },
  inheritAttrs: false
})
export default class MswModal extends Vue {
  smFullscreen = false
}

