import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-yellow-700 font-bold" }
const _hoisted_2 = { class: "text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("span", null, [
    _createVNode("span", _hoisted_1, _toDisplayString(_ctx.vanityAddress.slice(0, _ctx.prefix.length)), 1),
    _createVNode("span", _hoisted_2, _toDisplayString(_ctx.vanityAddress.slice(_ctx.prefix.length)), 1)
  ]))
}