import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/tailwind.css'
import router from './router'

// Create app
const app = createApp(App).use(router).mount('#app')

// Document title sync
const defaultTitle = 'The Million Satoshi Website'

router.afterEach((to, from) => {
  app.$nextTick(() => {
    const routeName = to.name
    const routeTitle = to.meta.title as string
    const isHome = routeName === 'home'
    document.title = (isHome ? defaultTitle : routeTitle) || defaultTitle
  })
})
