
import { Options, Vue } from 'vue-class-component'
import { XIcon } from '@heroicons/vue/outline'
import QRCodeStyling from 'qr-code-styling'
import qrcodeOptions from '@/qr-code-styling-options'

import MswModal from '@/components/Modal.vue'
import MswVanityAddress from '@/components/VanityAddress.vue'

@Options({
  props: {
    vanityAddress: String,
    prefix: String
  },
  emits: ['dismissed'],
  components: { MswModal, MswVanityAddress, XIcon }
})
export default class MswQrcodeModal extends Vue {
  vanityAddress = '<vanity address value missing>'
  prefix = ''

  mounted(): void{
    const qrCode = new QRCodeStyling({
      ...qrcodeOptions,
      width: 280,
      height: 280,
      type: 'svg'
    })
    const canvasElement = document.getElementById('qrcode-container') || undefined

    if (canvasElement) {
      canvasElement.innerHTML = ''
      qrCode.append(canvasElement)
      this.$forceUpdate()
    }
  }
}
