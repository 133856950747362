import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-0 right-0 fixed z-50 w-full md:max-w-sm p-4 py-14 md:p-4 md:py-14 max-h-screen overflow-hidden",
  role: "toasts"
}
const _hoisted_2 = { class: "flex-1 flex-col fade w-full mr-8 justify-end" }
const _hoisted_3 = { class: "text-white md:max-w-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.toasts.length > 0)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
            return (_openBlock(), _createBlock("div", {
              key: toast.id,
              class: [toast.variant.base, "cursor-pointer"],
              onClick: ($event: any) => (_ctx.removeToast(toast.id))
            }, [
              _createVNode("div", {
                class: ["flex items-center h-14 border-l-4 py-3 px-3 shadow-lg mb-2", toast.variant.backgroundClass]
              }, [
                _createVNode("div", {
                  class: ["rounded-full bg-white mr-3", toast.variant.textClass]
                }, [
                  _createVNode("span", {
                    innerHTML: toast.variant.icon
                  }, null, 8, ["innerHTML"])
                ], 2),
                _createVNode("div", _hoisted_3, _toDisplayString(toast.message), 1)
              ], 2)
            ], 10, ["onClick"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}