import { createDecorator, VueDecorator } from 'vue-class-component'

type ErrorHandler<Component> = (source: Component, error: Error) => void

export function createRequireDecorator<Component>(asyncActionSelector: (target: Component) => Promise<unknown | Error>, errorHandler?: ErrorHandler<Component>): VueDecorator {
  return createDecorator((options, key) => {
    const originalMethod = options.methods[key]

    options.methods[key] = async function wrapperMethod(...args: unknown[]) {
      const action = asyncActionSelector(this)
      let error = null

      try {
        const result = await action
        error = result instanceof Error ? result : null
      } catch (caughtError) {
        error = caughtError
      }

      if (error == null) {
        return originalMethod.apply(this, args)
      } else if (errorHandler) {
        errorHandler(this, error)
      }
    } // end of wrapped method
  })
}
