
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Options, Vue } from 'vue-class-component'
import firebase from 'firebase/app'
import { auth, authUi, authUiConfig, database } from '@/firebase'
import { loadStripe, Stripe, stripeConfig } from '@/stripe'
import { CollectionIcon, ChevronRightIcon } from '@heroicons/vue/outline'

import MswStep, { State as StepState, StepProps } from '@/components/Step.vue'
import MswButton from '@/components/Button.vue'
import MswVanityAddress from '@/components/VanityAddress.vue'
import MswHeader from '@/components/Header.vue'
import MswFooter from '@/components/Footer.vue'
import MswErrorModal from '@/components/ErrorModal.vue'
import MswConfirmModal from '@/components/ConfirmModal.vue'
import MswLoginModal from '@/components/LoginModal.vue'
import MswToast, { ToastVariants } from '@/components/Toast.vue'
import { createRequireDecorator } from '@/decorators'

enum AppState {
  Loading = 'loading',
  Pristine = 'pristine',
  Submitting = 'submitting',
  CheckingDifficulty = 'checking_difficulty',
  Cancelling = 'cancelling',
  DifficultyUnacceptable = 'difficulty_unacceptable',
  DonationRequest = 'donation_request',
  Donating = 'donating',
  Queued = 'queued',
  Mining = 'mining',
  Result = 'result'
}

enum CheckoutResult {
  Completed = 'completed',
  Cancelled = 'cancelled',
}

type VanityAddressResult = {
  vanityAddress: string,
  privateKey: string
}

type ServerData = {
  difficultyAcceptable: boolean | null,
  donationAmount: number | null,
  stripeCheckoutSessionId: string | null,
  definitiveCheckoutResult: CheckoutResult | null,
  pickedUpFromQueue: boolean | null,
  result: VanityAddressResult | null
}

const base58Chars = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'

function getRandomString() {
  return [...Array(12)].map(() => Math.random().toString(36)[2]).join('')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPermissionDeniedError(error: any): boolean {
  return error?.message?.includes('PERMISSION_DENIED')
}

@Options({
  components: {
    MswStep,
    MswButton,
    MswHeader,
    MswFooter,
    MswErrorModal,
    MswConfirmModal,
    MswLoginModal,
    MswToast,
    MswVanityAddress,
    CollectionIcon,
    ChevronRightIcon
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeRouteUpdate: (to, from, next): void => {
    next()
  }
})
export default class App extends Vue {
  get isSessionLoading(): boolean {
    return this.appState === AppState.Loading
  }

  get isLoggedIn(): boolean {
    return (
      (this.user != null && !this.user.isAnonymous) ||
      (this.awaitingSignIn && this.wasSignedIn) // To prevent layout to shift when sign-in completes and user info is loaded.
    )
  }

  get displayName(): string {
    let name = this.user?.email || this.user?.displayName

    const isEmpty = (name == null || name.trim().length === 0)
    const hasProviderData = this.user?.providerData != null && this.user.providerData.length > 0

    if (isEmpty && hasProviderData) {
      const providerData = this.user?.providerData[0]
      name = providerData!.email || providerData!.displayName
    }

    return name || 'mister Satoshi'
  }

  get disableInput(): boolean {
    return this.appState !== AppState.Pristine
  }

  get isGivenPrefixValid(): boolean {
    return [...this.givenPrefix].every(char => base58Chars.indexOf(char) >= 0)
  }

  get showCheckDifficulty(): boolean {
    return (this.appState === AppState.Pristine || this.appState === AppState.Submitting)
  }

  get disableCheckDifficulty(): boolean {
    const allowSubmit = this.showCheckDifficulty && (this.givenPrefix || '').length > 0 && this.isGivenPrefixValid
    return !allowSubmit
  }

  get isSubmitting(): boolean {
    return this.appState === AppState.Submitting
  }

  get showCancel(): boolean {
    return this.appState === AppState.CheckingDifficulty || this.appState === AppState.Cancelling
  }

  get showRestart(): boolean {
    return this.appState === AppState.DifficultyUnacceptable || this.appState === AppState.DonationRequest || this.appState === AppState.Result
  }

  get isCancelling(): boolean {
    return this.appState === AppState.Cancelling
  }

  get showDonate(): boolean {
    return this.appState === AppState.DonationRequest
  }

  get isDonating(): boolean {
    return this.appState === AppState.Donating
  }

  appState = AppState.Pristine
  givenPrefix = ''
  logs: StepProps[] = []
  sessionCreated = false
  stripeCheckoutSessionId: string | null = null
  donationAmount: number | null = null
  signInAction: Promise<void | Error> = Promise.resolve()
  loadStripeAction: Promise<void | Error> = Promise.resolve()
  showLoginModal = false
  result: VanityAddressResult | null = null
  isPasswordReset = false

  declare $refs: {
    toast: MswToast,
  }

  // Method decorators; these are cool, right?
  static requireSignIn = createRequireDecorator<App>(self => self.signInAction) // Require anonymous sign in or better.
  static requireStripe = createRequireDecorator<App>(self => self.loadStripeAction, self => self.showStripeError)

  created(): void {
    // Pluck Stripe URL params
    const url = new URL(window.location.href)
    const completedCheckoutSessionId = url.searchParams.get(stripeConfig.CheckoutCompletedUrlParam)
    const cancelledCheckoutSessionId = url.searchParams.get(stripeConfig.CheckoutFailedUrlParam)
    url.searchParams.delete(stripeConfig.CheckoutCompletedUrlParam)
    url.searchParams.delete(stripeConfig.CheckoutFailedUrlParam)
    window.history.pushState({}, document.title, url.href)

    // Handle password reset flow
    this.handlePasswordReset()

    // If there is an existing session, it will need to be restored.
    if ((this.hasSessionId())) {
      this.appState = AppState.Loading
    }

    // Subscribe to Firebase auth state updates.
    this.watchFirebaseAuthState()

    const safelyRestoreSession = () => { // wrapper around `restoreSession` that deals with errors.
      return this.restoreSession()
        .then(restoredAppState => {
          this.appState = restoredAppState
        })
        .catch(error => {
          error && this.showError()
          console.log(error)
          this.appState = AppState.Pristine
        })
    }

    // Sign in asynchronously. Any error will be shown immediately.
    this.signInAction = this.signInAnonymously()
      .then(safelyRestoreSession)
      .catch(error => {
        error && this.showSignInError()
        console.log(error)
        return error
      })

    // Load stripe asynchronously. Any error will be shown only at the moment when Stripe is required.
    this.loadStripeAction = this.loadStripe().catch(error => error)

    // Handle Stripe checkout callbacks
    this.signInAction.then(() => {
      if (completedCheckoutSessionId != null) {
        this.onStripeCallback(completedCheckoutSessionId, CheckoutResult.Completed)
      } else if (cancelledCheckoutSessionId != null) {
        this.onStripeCallback(cancelledCheckoutSessionId, CheckoutResult.Cancelled)
      }
    })
  }

  handlePasswordReset(): void {
    const url = new URL(window.location.href)
    this.isPasswordReset = url.searchParams.get('mode') === 'resetPassword'

    if (this.isPasswordReset) {
      console.log('Handle password reset.')

      if (this.isAuthenticated() && !this.user.isAnonymous) {
        this.signOut(false) // Sign out without reloading the page.
      }

      this.promptLogin().finally(() => {
        this.$router.push({ name: 'home' })
        this.isPasswordReset = false
      })
    }
  }

  // A kind of 'replay' that restores the state of the client according to the session data that was persisted in the database.
  async restoreSession(): Promise<AppState> {
    let restoredAppState = this.appState

    if (restoredAppState === AppState.Loading) {
      restoredAppState = AppState.Pristine
    }

    if (!this.isAuthenticated()) {
      return restoredAppState
    }

    if (localStorage.getItem('generateVanityAddressSessionId') == null) {
      return restoredAppState
    }

    // Before reading anything from the database, trigger an access token refresh.
    if (this.user.refreshToken != null) {
      await this.user.getIdToken(true)
    }

    const sessionData = (await this.getDbSession()!.get()).val()

    if (sessionData == null) {
      return restoredAppState
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { clientData, serverData } = sessionData as { clientData: any, serverData: ServerData }

    if (clientData == null) {
      return restoredAppState
    }

    if (clientData.givenPrefix != null) {
      console.log('Restore prefix')
      this.givenPrefix = clientData.givenPrefix
    }

    if (serverData == null) {
      this.listenForDifficultyEstimate()
      return restoredAppState
    } else {
      this.logCheckingDifficulty()
      restoredAppState = AppState.CheckingDifficulty
    }

    if (serverData == null) {
      this.listenForDifficultyEstimate()
      return restoredAppState
    }

    if (serverData.difficultyAcceptable === true) {
      if (serverData.stripeCheckoutSessionId == null) {
        this.showError()
        return restoredAppState
      }

      console.log('Restore stripe checkout session id & donation amount.')
      this.finishLastStep()
      this.stripeCheckoutSessionId = serverData.stripeCheckoutSessionId
      this.donationAmount = serverData.donationAmount
      restoredAppState = AppState.DonationRequest
    } else {
      this.rewindLastStep()
      this.logDifficultyUnacceptable()
      restoredAppState = AppState.DifficultyUnacceptable
      return restoredAppState
    }

    this.logDonationPending()

    const checkoutResult = serverData.definitiveCheckoutResult || clientData.tentativeCheckoutResult

    if (checkoutResult !== CheckoutResult.Completed) {
      return restoredAppState
    }

    this.logQueueing()
    restoredAppState = AppState.Queued

    this.logMining()
    restoredAppState = AppState.Mining

    if (serverData.result?.vanityAddress == null) {
      this.listenForResult()
      return restoredAppState
    }

    this.finishLastStep()
    this.result = serverData.result
    restoredAppState = AppState.Result

    return restoredAppState
  }

  onFormSubmit(): void {
    if (this.appState === AppState.Pristine && this.givenPrefix) {
      this.checkDifficulty()
    }
  }

  openVault(): void {
    try {
      this.$router.push({ name: 'vault' })
    } catch (error) {
      console.log(error)
      this.$refs.toast.showToast('Something went wrong.', ToastVariants.Error)
    }
  }

  @App.requireSignIn // Wait for sign in to finish, and show a modal with a message if an error happens.
  async checkDifficulty(): Promise<void> {
    if (!this.isAuthenticated()) { // Check authentication status again for good measure, and enjoy the benefits of the Typescript type guard.
      this.showSignInError()
      return
    }

    if ((this.givenPrefix || '').length === 0) {
      return // shouldn't happen, but if it does I guess its safe to ignore it.
    }

    const originalAppState = this.appState

    // Push new session data object to server
    this.appState = AppState.Submitting

    const dbRoot = this.getDbRoot()!
    const dbSession = this.getDbSession()!

    if (!this.sessionCreated) {
      try {
        await dbSession.update({
          uid: this.user.uid,
          clientData: {
            creationTime: firebase.database.ServerValue.TIMESTAMP,
            givenPrefix: this.givenPrefix
          }
        })
      } catch (error) {
        console.log(error)

        if (isPermissionDeniedError(error)) {
          this.showError()
          return
        }

        this.$refs.toast.showToast('Failed to reach the server.', ToastVariants.Error) // Use toats to display errors on actions that a user may retry.
        this.appState = originalAppState
        return
      }

      this.sessionCreated = true
    }

    // Ask server for an estimate of the difficulty of finding a prefix.
    try {
      await dbRoot.child('vanityAddressDifficultyEstimateRequests').push({
        uid: this.user.uid,
        generateVanityAddressSessionId: dbSession.key,
        creationTime: firebase.database.ServerValue.TIMESTAMP,
        givenPrefix: this.givenPrefix,
        clientWebsiteDomain: location.origin,
        userEmail: this.user.email
      })
    } catch (error) {
      console.log(error)

      if (isPermissionDeniedError(error)) {
        this.showError()
        return
      }

      this.$refs.toast.showToast('Failed to reach the server.', ToastVariants.Error)
      this.appState = originalAppState
      return
    }

    this.listenForDifficultyEstimate()
  }

  @App.requireSignIn
  async listenForDifficultyEstimate(): Promise<void> {
    console.log('Start listening for a difficulty estimate from the server.')

    let getDifficultyEstimate = null
    let result = null

    try {
      getDifficultyEstimate = this.listenForServerUpdate(serverData => {
        if (serverData?.difficultyAcceptable == null) {
          return null // Ignore this update, because it does not have the necessary info yet.
        }

        return [
          serverData.difficultyAcceptable,
          serverData.stripeCheckoutSessionId,
          serverData.donationAmount
        ] as [boolean, string, number]
      })

      // Update state
      this.appState = AppState.CheckingDifficulty
      this.logCheckingDifficulty()

      // Await
      result = await getDifficultyEstimate
    } catch (error) {
      const _error = error as { message: string }

      if (_error.message === 'New session started since this async operation was started.') { // TODO: refactor
        console.log(_error.message)
        return // ignore this error
      }

      console.log('Something went wrong when trying to subscribe to (or while awaiting) a difficulty estimate from the server.')
      console.log(error)
      this.showError()
      return
    }

    if (result.some(val => val == null)) {
      console.log('The backend didn\'t provide all necessary info in the difficulty estimate.')
      this.showError()
      return
    }

    this.onDifficultyEstimateReceived(...result)
  }

  onDifficultyEstimateReceived(accepted: boolean, stripeCheckoutSessionId: string, donationAmount: number): void {
    if (!accepted) {
      console.log('Difficulty too high, not accepted.')
      this.appState = AppState.DifficultyUnacceptable
      this.rewindLastStep()
      this.logDifficultyUnacceptable()
      return
    }

    if (stripeCheckoutSessionId == null) {
      this.showError()
      return
    }

    if (this.appState === AppState.CheckingDifficulty) {
      this.finishLastStep()
      this.stripeCheckoutSessionId = stripeCheckoutSessionId
      this.donationAmount = donationAmount
      this.appState = AppState.DonationRequest
    }
  }

  @App.requireSignIn
  async cancel(): Promise<void> {
    if (this.sessionCreated && this.isAuthenticated()) {
      try {
        this.getDbSession()!.update({
          'clientData/isSessionCancelled': true
        }).catch(error => {
          console.log('Something went wrong while trying to cancel the session.')
          console.log(error)
        })
      } catch (error) { // Swallow error, we cancel on a best-effort basis.
        console.log('Something went wrong when trying to cancel the session.')
        console.log(error)
      }
    }

    this.restart()
  }

  @App.requireSignIn
  @App.requireStripe
  async donate(): Promise<void> {
    if (this.stripe == null) {
      this.showStripeError()
      return
    }

    if (this.stripeCheckoutSessionId == null) { // Shouldn't happen, unless there is a backend configuration issue.
      console.log('Missing a Stripe checkout session ID, unable to proceed with the donation.')
      this.showError()
      return
    }

    if (this.user == null) {
      console.log('this.user should not be null at this point.')
      this.showError()
      return
    }

    // Ask user to log in.
    if (this.user.isAnonymous) {
      const loginSuccess = await this.promptLogin()
      console.log(this.user)
      if (!loginSuccess) {
        this.$refs.toast.showToast('Please sign in before making a donation.', ToastVariants.Info)
        return
      }
    }

    // Check once more, just in case.
    if (this.user == null || this.user.isAnonymous) {
      this.$refs.toast.showToast('Cannot proceed with the donation because you are not signed in.', ToastVariants.Error)
    }

    // Redirect the logged-in user to the Stripe checkout.
    try {
      var redirectToCheckout = this.stripe.redirectToCheckout({
        sessionId: this.stripeCheckoutSessionId
      })
    } catch (error) {
      console.log(error)
      this.showError()
      return
    }

    this.appState = AppState.Donating
    this.logDonationPending()

    await redirectToCheckout
  }

  updateStateOnCheckoutResult(result: string, isDefinitive = false): void {
    const allow =
      (this.appState === AppState.DonationRequest || this.appState === AppState.Donating) ||
      (isDefinitive && this.appState === AppState.Queued)

    if (!allow) {
      return
    }

    if (result === CheckoutResult.Completed) {
      if (this.appState !== AppState.Queued) {
        this.appState = AppState.Queued
        this.logQueueing()
      }
    } else {
      this.appState = AppState.DonationRequest
      this.rewindLastStep()
    }
  }

  @App.requireSignIn
  async onStripeCallback(checkoutSessionId: string, checkoutResult: CheckoutResult): Promise<void> {
    if (!this.isAuthenticated()) {
      return Promise.reject(new Error('Client not authenticated, therefor unable to inform the server about the completed checkout.'))
    }

    const dbRoot = this.getDbRoot()!
    const dbSession = this.getDbSession()!

    // Notify server about a completed checkout
    if (checkoutResult === CheckoutResult.Completed) {
      const testPaymentsSecret = process.env.VUE_APP_TEST_PAYMENTS_DEVELOPMENT_SECRET || null

      try {
        await dbRoot.child('stripeCheckoutCallbacks').push({
          uid: this.user.uid,
          creationTime: firebase.database.ServerValue.TIMESTAMP,
          generateVanityAddressSessionId: dbSession.key,
          stripeCheckoutResult: CheckoutResult.Completed,
          testPaymentsSecret
        })
      } catch (error) {
        console.log(error)
        // Don't inform the user about this failure, he should be able to proceed using the app regardless.
      }
    }

    // If the checkout is related to the current session, then already note in the user's session that it is completed. Definitive confirmation will come from the server though.
    if (checkoutSessionId === this.stripeCheckoutSessionId) {
      console.log('Checkout session id of Stripe callback matches the checkout session id of this session.')

      this.updateStateOnCheckoutResult(checkoutResult)

      try {
        await dbSession.update({
          'clientData/tentativeCheckoutResult': checkoutResult
        })

        if (this.user.email) {
          await dbRoot.child('userData').child(this.user.uid).update({
            email: this.user.email
          })
        }
      } catch (error) {
        console.log(error)
        // Don't inform the user about this failure, he should be able to proceed using the app regardless.
      }
    } else {
      console.log('Checkout session id of Stripe callback is different than the checkout session id of this session.')
    }

    // Listen for the definitive checkout result, as verified by the server
    if (checkoutSessionId === this.stripeCheckoutSessionId && checkoutResult === CheckoutResult.Completed) {
      console.log('Listen for checkout confirmation from server.')

      this.listenForServerUpdate(serverData => serverData.definitiveCheckoutResult)
        .then(definitiveCheckoutResult => this.onCheckoutConfirmation(definitiveCheckoutResult))
        .catch(() => this.showError())
    }
  }

  @App.requireSignIn
  async onCheckoutConfirmation(definitiveCheckoutResult: CheckoutResult): Promise<void> {
    if (!this.isAuthenticated()) {
      return Promise.reject(new Error('Client not authenticated, therefor unable to subscribe to progress updates from server.'))
    }

    this.updateStateOnCheckoutResult(definitiveCheckoutResult, true)

    if (definitiveCheckoutResult === CheckoutResult.Completed) {
      this.listenForResult() // Listen for a vanity address result from the server
    }
  }

  @App.requireSignIn
  async listenForResult(): Promise<void> {
    console.log('Listen for vanity address result from server.')

    try {
      this.listenForServerUpdate(serverData => serverData.result).then(
        result => this.onResultReady(result)
      ).catch(error => {
        console.log('Something went wrong while listening for a vanity address result from the server.')
        console.log(error)
        this.showError()
      })
    } catch (error) {
      console.log('Something went wrong while trying to subscribe to a vanity address result from the server.')
      console.log(error)
      this.showError()
    }
  }

  onResultReady(result: VanityAddressResult) : void {
    this.appState = AppState.Mining
    this.logMining()

    this.appState = AppState.Result
    this.finishLastStep()

    this.result = result
  }

  @App.requireSignIn
  async listenForServerUpdate<TResult>(filter: (serverData: ServerData) => TResult | null): Promise<TResult> {
    if (!this.isAuthenticated()) {
      return Promise.reject(new Error('Client not authenticated, therefor unable to subscribe to updates from server.'))
    }

    const dbSession = this.getDbSession()
    const dbRef = dbSession!.child('serverData')

    return new Promise((resolve, reject) => {
      const originalSessionId = dbSession!.key

      const listener = dbRef.on('value',
        snapshot => {
          // TODO: Make sure im looking at the correct version of the document (the snapshot must be more recent than before). Kleine kans dat dit gebeurt.

          // Check if a new session was started since this async operation started
          const currrentDbSessionId = this.getDbSession()?.key

          if (currrentDbSessionId !== originalSessionId) {
            dbRef.off('value', listener)
            reject(new Error('New session started since this async operation was started.'))
            return
          }

          // Check if we have a value for the field we're monitoring
          const value = snapshot.val()
          const result = filter(value)

          if (result == null) {
            return
          }

          // We have a value, so unsubscribe and resolve the promise
          dbRef.off('value', listener)
          resolve(result)
        },
        error => {
          dbRef.off('value', listener)
          reject(error)
        }
      ) // end of listener
    }) // end of Promise
  }

  restart(): void {
    this.clearSessionId()
    this.sessionCreated = false
    this.result = null
    this.givenPrefix = ''
    this.logs = []
    this.appState = AppState.Pristine
  }

  // <user-login>
  __onLoginModalClosed: (() => void) | null = null
  upgradingFromAnonymous = false

  @App.requireSignIn // User should be signed in as an anonymous user already.
  async promptLogin(): Promise<boolean> {
    // Setup a promise that will resolve when the user login comnpletes.
    const login = new Promise<boolean>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onSuccess = (user: any) => {
        console.log('Login success (without merge conflict)')
        this.user = null
        this.$forceUpdate()
        this.user = user
        resolve(true)
        return false // Tell Firebase auth UI not to redirect automatically.
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onError = async(error: any) => {
        if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
          console.log(`Login failed: ${error.code}`)
          this.showError()
          resolve(false)
          return
        } else {
          console.log('Merge conflict on login, need to upgrade from anonymous user.')
        }

        if (!this.isAuthenticated()) {
          console.log('Error: at this point the client should already have been logged in as an anonymous user.')
          this.showError()
          resolve(false)
          return
        }

        try {
          this.upgradingFromAnonymous = true
          await this.upgradeFromAnonymous(error.credential) // The credential the user tried to sign in with.
          resolve(true)
        } catch (error) {
          console.log('Error while trying to upgrade the anonymous user session.')
          console.log(error)
          this.showError()
          resolve(false)
        } finally {
          this.upgradingFromAnonymous = false
        }

        // TODO: delete the anonymous user
      }

      this.openLoginModal(onSuccess, onError).then(() => {
        // Reject on modal dismissal.
        reject(new Error('Login modal dismissed.'))
      })
    })

    try {
      const loginSuccess = await login
      this.$forceUpdate()
      return loginSuccess
    } catch (error) {
      console.log(`Login failed, probably due to the login modal being closed. ${error}`)
      return false
    } finally {
      this.closeLoginModal()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async upgradeFromAnonymous(newCredential: any): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Apparently something went wrong: anonymous sign-in should have been completed at this point.')
    }

    const dbSession = this.getDbSession()!
    const uidUpdateSecret = getRandomString()

    // Prepare auth uid update by storing a secret.
    try {
      await dbSession.update({
        uid: this.user.uid,
        uidUpdateSecret
      })
    } catch (error) {
      console.log(error)
      throw new Error('Failed to update the session with a uidUpdateSecret')
    }

    console.log('Upgrading anonymous session')
    newCredential = await auth.signInWithCredential(newCredential)
    this.user = newCredential.user

    // Update the session with the new auth uid.
    try {
      await dbSession.update({
        uid: newCredential.user.uid,
        uidUpdateSecret // The secret will allow the new/upgraded user (with different uid) to overwrite the previous uid.
      })
    } catch (error) {
      console.log(error)
      throw new Error('Failed to update the session with a new (upgraded from anonymous) uid.')
    }

    // TODO: Erase the secret from the database
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openLoginModal(onSuccess: (user: any) => void, onError: (error: any) => void, selfInvoke = false): Promise<void> {
    if (!selfInvoke && this.showLoginModal) {
      console.log('Skip opening login modal since it is opened already.')
      return Promise.resolve()
    }

    if (!this.showLoginModal) {
      this.showLoginModal = true
      this.$forceUpdate()

      return new Promise(resolve => {
        setTimeout(() => { // Give Vue a chance to render the login modal, then call back to this method.
          this.openLoginModal(onSuccess, onError, true).then(resolve)
        }, 1)
      })
    }

    // Modal should be rendered/mounted/visible now.

    const waitForModalDismissal = new Promise<void>((resolve) => {
      this.__onLoginModalClosed = resolve
    })

    authUi.start('#firebase-auth-ui', {
      // Default global config, as imported from our Firebase module.
      ...authUiConfig,
      // Extra config
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          onSuccess(authResult.user)
          return false
        },
        signInFailure: (error) => {
          onError(error)
        }
      }
    })

    return waitForModalDismissal
  }

  closeLoginModal(): void {
    if (!this.showLoginModal) {
      console.log('Skip closing login modal since it is closed already.')
      return
    }

    this.showLoginModal = false
    setTimeout(() => authUi.reset(), 100) // Unmount auth UI after modal fades out.

    // eslint-disable-next-line no-unused-expressions
    this.__onLoginModalClosed?.()
    this.__onLoginModalClosed = null
  }
  // </user-login>

  // <logging>
  logCheckingDifficulty(): void {
    this.appendLog('Checking difficulty', 'Difficulty acceptable', 'Failed to check the difficulty')
  }

  logDifficultyUnacceptable(): void {
    const message = 'Sorry, it is too difficult to find a vanity address with the prefix you asked for. Try something else or something shorter please.'
    this.appendLog(message, message, message, StepState.Failed)
  }

  logDonationPending(): void {
    this.appendLog('Waiting for donation', 'Donation completed', 'Donation failed')
  }

  logQueueing(): void {
    this.appendLog('Waiting in the queue', 'Picked up from queue', 'Something went wrong while waiting')
  }

  logMining(): void {
    this.appendLog('Mining your vanity address', 'Mining finished', 'Mining failed')
  }

  appendLog(description: string, onSuccessDescription: string, onFailureDescription: string, stepState = StepState.Running): void {
    this.finishLastStep()
    this.logs.push({
      description,
      onSuccessDescription,
      onFailureDescription,
      state: stepState
    })
  }

  failLastStep(): void{
    this.finishLastStep(false)
  }

  finishLastStep(success = true): void {
    if (!this.logs.length) {
      return
    }

    this.logs.push({
      description: '', // default
      onSuccessDescription: '', // default
      onFailureDescription: '', // default
      ...this.logs.pop(),
      state: success ? StepState.Succeeded : StepState.Failed
    })
  }

  rewindLastStep(): void {
    this.logs.pop()
  }
  // </logging>

  // <database>
  // eslint-disable-next-line camelcase
  getDbRoot(): firebase.database.Reference | null {
    return this.isAuthenticated() ? database.ref() : null
  }

  getDbSession(): firebase.database.Reference | null {
    if (!this.isAuthenticated()) {
      return null
    }

    let existingSessionId = localStorage.getItem('generateVanityAddressSessionId')
    console.log(`Loaded existing session id from local storage: ${existingSessionId}`)

    if (existingSessionId == null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const session = this.getDbRoot()!.child('generateVanityAddressSessions').push()

      if (session.key == null) {
        throw Error('Pushed a new session to the Firebase database, but the result does not have a key.')
      }

      localStorage.setItem('generateVanityAddressSessionId', session.key)
      existingSessionId = session.key
      console.log(`New session id: ${existingSessionId}`)
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.getDbRoot()!.child('generateVanityAddressSessions').child(existingSessionId)
  }

  hasSessionId(): boolean {
    return localStorage.getItem('generateVanityAddressSessionId') != null
  }

  clearSessionId(): void {
    console.log('Remove session id')
    localStorage.removeItem('generateVanityAddressSessionId')
  }
  // </database>

  // <authentication>
  awaitingSignIn = true
  user: firebase.User | null = null

  isAuthenticated(): this is this & { user: firebase.User } {
    return this.awaitingSignIn === false && (this.user?.uid || '').length !== 0
  }

  get wasSignedIn(): boolean { // Was signed in, non-anonymously
    return localStorage.getItem('signedIn') != null
  }

  watchFirebaseAuthState(): firebase.Unsubscribe {
    return auth.onAuthStateChanged(user => {
      if (user != null && user.uid !== this.user?.uid) {
        console.log(`User logged in: ${user.isAnonymous ? 'Anon' : (user!.displayName || user!.email)}`)
        console.log(`Logged-in user uid: ${user!.uid}`)
      }

      if (user != null && !user.isAnonymous) {
        localStorage.setItem('signedIn', 'yes')
      }

      if (user == null || user.isAnonymous) {
        localStorage.removeItem('signedIn')
      }
      this.user = user
    })
  }

  signOut(reload = true): void { // TODO: Should we disallow sign-out while, for example, upgrading from an anonymous session?
    auth.signOut()
    localStorage.clear()

    if (reload) {
      window.location.reload()
    }
  }

  async signInAnonymously(): Promise<void> {
    if (this.user == null) {
      await this.loadCurrentUser()
    }

    if (this.user != null) {
      console.log(`No need for anonymous sign-in, already signed in as: ${this.user.displayName || this.user.email || 'Anon'}.`)
      this.awaitingSignIn = false
      return
    }

    console.log('Sign in anonymously')
    const cred = await auth.signInAnonymously()

    if (cred.user == null) {
      throw new Error('Firebase user credential has no value for its user property.')
    }

    this.awaitingSignIn = false
    this.user = cred.user

    console.log('Anonymous user uid (after sign-in): ' + this.user.uid)
  }

  async loadCurrentUser() : Promise<void> {
    return new Promise((resolve /*, reject */) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        this.user = user
        resolve()
        unsubscribe()
      })
    })
  }
  // </authentication>

  // <stripe>
  stripe: Stripe | null = null

  loadStripe(): Promise<void> {
    return loadStripe()
      .then(stripe => {
        this.stripe = stripe
      })
  }
  // </stripe>

  // <modal>
  modalProps: { title: string, message: string, buttonText: string, hideButtons: boolean } | null = null

  showError(): void {
    this.showModal('Error', 'Something unexpected happened. Please try reloading the web app.')
  }

  showSignInError(): void {
    this.showModal('Error', 'Failed to connect to the user authentication service, which is required even if you are an anonymous user. Please try reloading the web app.')
  }

  showStripeError(): void {
    this.showModal('Error', 'Failed to load the Stripe checkout component that is required to process your donation. Please try reload the web app.')
  }

  showModal(title: string, message: string, buttonText = 'Reload', hideButtons = false): void {
    this.modalProps = { title, message, buttonText, hideButtons }
  }

  hideModal(): void {
    this.modalProps = null
  }

  onModalDismissed(): void {
    localStorage.clear()
    // TOOD: proberen uit te loggen?
    window.location.reload()
  }
  // </modal>
}
