
import { Options, Vue } from 'vue-class-component'
import firebase from 'firebase/app'
import { auth, database } from '@/firebase'
import { EyeIcon, EyeOffIcon, DocumentDuplicateIcon as CopyAddressIcon, QrcodeIcon, XCircleIcon } from '@heroicons/vue/outline'
import { DocumentDuplicateIcon as CopyPrivateKeyIcon } from '@heroicons/vue/solid'

import MswHeader from '@/components/Header.vue'
import MswFooter from '@/components/Footer.vue'
import MswVanityAddress from '@/components/VanityAddress.vue'
import MswToast, { ToastVariants } from '@/components/Toast.vue'
import MswConfirmModal from '@/components/ConfirmModal.vue'
import SpinnerIcon from '@/components/SpinnerIcon.vue'
import MswQrcodeModal from '@/components/QrcodeModal.vue'

type VanityAddressResult = {
  vanityAddress: string,
  privateKey: string
}

type VanityAddress = {
  key: string,
  prefix: string,
  result: VanityAddressResult | null,
  creationTime: number
}

enum ViewMode {
  ViewVanityAddress,
  ManagePrivateKey
}

@Options({
  components: {
    MswHeader,
    MswFooter,
    MswToast,
    MswConfirmModal,
    SpinnerIcon,
    MswVanityAddress,
    MswQrcodeModal,
    EyeIcon,
    EyeOffIcon,
    CopyAddressIcon,
    QrcodeIcon,
    XCircleIcon,
    CopyPrivateKeyIcon
  }
})
export default class Vault extends Vue {
  unsubscribeUpdateListener: firebase.Unsubscribe | null = null
  vanityAddresses: VanityAddress[] = []
  ViewMode = ViewMode
  viewMode = ViewMode.ViewVanityAddress
  __onConfirmModalDismissed: ((confirmed: boolean) => void) | null = null
  showQr = false
  displayedVanityAddress: VanityAddress | null = null

  declare $refs: {
    toast: MswToast,
  }

  created(): void {
    this.loadCurrentUser().then(() => {
      if (!this.isAuthenticated()) {
        return
      }

      this.unsubscribeUpdateListener = this.listenForUpdates()
      this.unsubscribeAuthWatcher = this.watchFirebaseAuthState()
    })
  }

  toggleMode(): void {
    this.viewMode = this.viewMode === ViewMode.ViewVanityAddress ? ViewMode.ManagePrivateKey : ViewMode.ViewVanityAddress
  }

  showQrcode(vanityAddress: VanityAddress): void {
    this.displayedVanityAddress = vanityAddress

    this.showQr = true
  }

  onQrcodeDismissed(): void {
    console.log('dismissed')
    this.showQr = false
    this.displayedVanityAddress = null
  }

  async copyVanityAddress(vanityAddress: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(vanityAddress)
      this.$refs.toast.showToast('Bitcoin address copied.', ToastVariants.Info)
    } catch (error) {
      console.log(error)
      this.$refs.toast.showToast('Could not copy the Bitcoin address, this web app might not have access to your clipboard.', ToastVariants.Error)
    }
  }

  async copyPrivateKey(privateKey: string): Promise<void> {
    if (privateKey == null) {
      this.$refs.toast.showToast('There is no private key to copy, it was probably erased.', ToastVariants.Error)
      return
    }

    try {
      await navigator.clipboard.writeText(privateKey)
      this.$refs.toast.showToast('Bitcoin private key copied.', ToastVariants.Info)
    } catch (error) {
      console.log(error)
      this.$refs.toast.showToast('Could not copy the Bitcoin private key, this web app might not have access to your clipboard.', ToastVariants.Error)
    }
  }

  async erasePrivateKey(vanityAddress: string, vanityAddressKey: string): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated, cannot reach Firebase to erase the private key.')
    }

    const waitForModalDismissal = new Promise<boolean>((resolve) => {
      this.__onConfirmModalDismissed = resolve
    })

    this.showWarning(vanityAddress)

    const confirmErase = await waitForModalDismissal

    if (!confirmErase) {
      this.hideModal()
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dbRoot = this.getDbRoot()!
    const ref = dbRoot.child(`userData/${this.user.uid}/vanityAddresses/${vanityAddressKey}/result/privateKey`)
    console.log(`userData/${this.user.uid}/vanityAddresses/${vanityAddressKey}/result/privateKey`)
    try {
      await ref.set(null)
      this.$refs.toast.showToast('Bitcoin private key erased.', ToastVariants.Info)
    } catch (error) {
      console.log(error)
      this.$refs.toast.showToast('Failed to reach the server.', ToastVariants.Error)
    } finally {
      this.hideModal()
    }
  }

  listenForUpdates(): firebase.Unsubscribe {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated, cannot subscribe to Firebase updates.')
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dbRoot = this.getDbRoot()!
    const ref = dbRoot.child(`userData/${this.user.uid}/vanityAddresses`)

    ref.on('value', (snapshot) => {
      const entries = Object.entries(snapshot.val() || {}) as [string, VanityAddress][]

      const keyedVanityAddresses = entries.map(([key, vanityAddress]) => {
        vanityAddress.key = key
        return vanityAddress
      })

      const update = keyedVanityAddresses
        .filter(i => i.prefix != null) // TODO: Also show those that don't have a result yet.
        .sort((a, b) => b.creationTime - a.creationTime)

      this.vanityAddresses.splice(0, this.vanityAddresses.length, ...update)
    })

    return () => ref.off()
  }

  // <database>
  getDbRoot(): firebase.database.Reference | null {
    return this.isAuthenticated() ? database.ref() : null
  }
  // </database>

  // <authentication>
  unsubscribeAuthWatcher: firebase.Unsubscribe | null = null
  user: firebase.User | null = null

  async loadCurrentUser() : Promise<void> {
    return new Promise((resolve /*, reject */) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        this.user = user
        this.onUserUpdated()
        resolve()
        unsubscribe()
      })
    })
  }

  isAuthenticated(): this is this & { user: firebase.User } {
    return this.user != null && !this.user.isAnonymous
  }

  watchFirebaseAuthState(): firebase.Unsubscribe {
    return auth.onAuthStateChanged(user => {
      this.user = user
      this.onUserUpdated()
    })
  }

  onUserUpdated(): void {
    // Redirect unauthenticated user to home page.
    if (this.user == null || this.user.isAnonymous) {
      this.$router.push({ name: 'home' })
    }
  }
  // </authentication>

  // <modal>
  modalProps: { title: string, message: string } | null = null

  onModalDismissed(): void {
    this.hideModal()
    // eslint-disable-next-line no-unused-expressions
    this.__onConfirmModalDismissed?.(false)
    this.__onConfirmModalDismissed = null
  }

  onModalConfirmed(): void {
    this.hideModal()
    // eslint-disable-next-line no-unused-expressions
    this.__onConfirmModalDismissed?.(true)
    this.__onConfirmModalDismissed = null
  }

  showWarning(vanityAddress: string): void {
    this.showModal('Warning', `Are you sure you want to erase the private key for address \`${vanityAddress}\`? Make sure you have it backed up somewhere.`)
  }

  showModal(title: string, message: string): void {
    this.modalProps = { title, message }
  }

  hideModal(): void {
    this.modalProps = null
  }
  // </modal>

  unmounted(): void {
    console.log('unmounting')

    // eslint-disable-next-line no-unused-expressions
    this.unsubscribeAuthWatcher?.()
    // eslint-disable-next-line no-unused-expressions
    this.unsubscribeUpdateListener?.()
  }
}
