import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-red-700 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner_icon = _resolveComponent("spinner-icon")
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")
  const _component_x_circle_icon = _resolveComponent("x-circle-icon")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.running)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_spinner_icon, { style: {"margin-left":"0.1rem","margin-right":"0.4rem"} }),
          _createTextVNode(" " + _toDisplayString(_ctx.description), 1)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.succeeded)
      ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_check_circle_icon, { class: "h-6 w-6 -ml-0 mr-1 -mt-1 text-gray-800 inline-block" }),
          _createTextVNode(" " + _toDisplayString(_ctx.onSuccessDescription || _ctx.description), 1)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.failed)
      ? (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_x_circle_icon, { class: "h-6 w-6 mr-2 -mt-1 inline-block text-red-700" }),
          _createVNode("span", _hoisted_1, _toDisplayString(_ctx.onFailureDescription || _ctx.onSuccessDescription || _ctx.description), 1)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}