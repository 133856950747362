import { createRouter, createWebHistory, RouteRecord, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Disclaimers from '@/views/Disclaimers.vue'
import Vault from '@/views/Vault.vue'
import Contact from '@/views/Contact.vue'
import About from '@/views/About.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/vault',
    name: 'vault',
    component: Vault,
    meta: {
      title: 'Vault'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Contact'
    }
  },
  {
    path: '/legal/terms',
    name: 'terms of use',
    component: TermsOfUse,
    meta: {
      title: 'Terms of use'
    }
  },
  {
    path: '/legal/privacy',
    name: 'privacy policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy policy'
    }
  },
  {
    path: '/disclaimers',
    name: 'disclaimers',
    component: Disclaimers,
    meta: {
      title: 'Disclaimers'
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0, behavior: 'auto' }
  }
})

export default router
