import { loadStripe, Stripe } from '@stripe/stripe-js'

// Get Stripe public key from env
const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
console.log(`Stripe public key: ${stripePublicKey}`)

if (stripePublicKey == null) {
  throw new Error('Stripe public key not set in `process.env`.')
}

// Config
const stripeConfig = {
  CheckoutCompletedUrlParam: 'checkout_completed',
  CheckoutFailedUrlParam: 'checkout_cancelled'
}

// Stripe load  utility
async function loadStripeSafely(): Promise<Stripe> {
  const stripe = await loadStripe(stripePublicKey)

  if (stripe == null) {
    throw new Error('Failed to load Stripe libraries.')
  }

  return stripe
}

// Exports
export {
  loadStripeSafely as loadStripe, // A more convenient version of 'loadStripe'
  Stripe, // Export the original as-is, for convenience.
  stripeConfig
}
