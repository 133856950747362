import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" }
const _hoisted_2 = { class: "sm:flex sm:items-start" }
const _hoisted_3 = { class: "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10" }
const _hoisted_4 = { class: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" }
const _hoisted_5 = {
  id: "modal-title",
  class: "text-lg leading-6 font-medium text-gray-900"
}
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = { class: "text-sm text-gray-500" }
const _hoisted_8 = {
  key: 0,
  class: "bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exclamation_circle_icon = _resolveComponent("exclamation-circle-icon")
  const _component_msw_modal = _resolveComponent("msw-modal")

  return (_openBlock(), _createBlock(_component_msw_modal, null, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_exclamation_circle_icon, { class: "h-7 w-7 text-red-600" })
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode("h3", _hoisted_5, _toDisplayString(_ctx.title), 1),
            _createVNode("div", _hoisted_6, [
              _createVNode("p", _hoisted_7, _toDisplayString(_ctx.message), 1)
            ])
          ])
        ])
      ]),
      (!_ctx.hideButtons)
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _createVNode("button", {
              type: "button",
              class: "w-full inline-flex justify-center rounded-sm shadow-sm hover:shadow-lg active:shadow-lg px-4 py-2 bg-gray-700 font-medium text-gray-100 hover:bg-gray-800 active:bg-yellow-600 sm:ml-3 sm:w-auto sm:text-sm",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('dismissed')))
            }, _toDisplayString(_ctx.buttonText), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}