
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    text: String,
    isSubmitting: Boolean,
    highlighted: Boolean
  }
})
export default class MswButton extends Vue {
  text = '<button text missing>'
  isSubmitting = false
  highlighted = false
}
