import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    disabled: _ctx.isSubmitting,
    class: ["rounded-sm block shadow-sm hover:shadow-lg active:shadow-lg\n          text-gray-100 text-sm py-2 px-4\n           transition-all ease-out duration-150 mb-2", {
      // Shared styles
      ... {'animate-pulse': _ctx.isSubmitting, 'active:bg-yellow-600': !_ctx.isSubmitting },
      // Styles that differ when highlighted
      ... (_ctx.highlighted ?
        { 'bg-blue-800': true, 'hover:bg-blue-900': !_ctx.isSubmitting} :
        { 'bg-gray-700': true, 'hover:bg-gray-800': !_ctx.isSubmitting}
      )
    }]
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, ["disabled"]))
}