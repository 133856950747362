import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import 'firebase/app-check'

// Firebase init
const config = {
  apiKey: 'AIzaSyAuMGFLUYeUaxUcCopx3vNhwi7a2m3gWY0',
  authDomain: 'google-auth.millionsatoshi.website',
  projectId: 'test-firebase-a1097',
  messagingSenderId: '307706922441',
  appId: '1:307706922441:web:6f7e17add841d8f6b71418',
  databaseURL: 'https://test-firebase-a1097-default-rtdb.europe-west1.firebasedatabase.app/'
}
firebase.initializeApp(config)

// App check
const appCheck = firebase.appCheck()
appCheck.activate('6Le8kYwbAAAAAHJu8EP7a9VgDMTp_b_AcqdkbjT3')

// Database
export const database = firebase.database()

// Auth
export const auth = firebase.auth()
auth.useDeviceLanguage()

// Auth UI
export const authUi = new firebaseui.auth.AuthUI(auth)
export const authUiConfig = {
  autoUpgradeAnonymousUsers: true,
  signInFlow: 'popup',
  signInSuccessUrl: 'https://millionsatoshi.website',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    {
      provider: 'microsoft.com'
    }
  ],
  tosUrl: 'https://millionsatoshi.website/legal/terms', // TODO: Get this from .env configuration.
  privacyPolicyUrl: 'https://millionsatoshi.website/legal/privacy'
}
