
import { Vue } from 'vue-class-component'

type Variant = {
  backgroundClass: string;
  textClass: string;
  name: string;
  icon: string;
};

type Toast = {
  id: number;
  message: string;
  timeout: number;
  index: number;
  variant: Variant;
};

export const ToastVariants = {
  Info: {
    backgroundClass:
      'bg-gradient-to-tr from-blue-600 to-blue-500 border-blue-700',
    textClass: 'text-blue-500',
    name: 'Info',
    icon: `<svg width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-info" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
        <circle cx="8" cy="4.5" r="1"/>
      </svg>`
  } as Variant,
  Success: {
    backgroundClass:
      'bg-gradient-to-tr from-green-600 to-green-500 border-green-700',
    textClass: 'text-green-500',
    name: 'Success',
    icon: `<svg width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
        </svg>`
  } as Variant,
  Warning: {
    backgroundClass:
      'bg-gradient-to-tr from-yellow-600 to-yellow-500 border-yellow-700',
    textClass: 'text-yellow-500',
    name: 'Info',
    icon: `<svg width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-exclamation" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
        </svg>`
  } as Variant,
  Error: {
    backgroundClass: 'bg-gradient-to-tr from-red-500 to-red-500 border-red-700',
    textClass: 'text-red-500',
    name: 'Error',
    icon: `<svg width="1.8em" height="1.8em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
        <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
      </svg>`
  } as Variant
}

let toastId = 0

export default class MswToast extends Vue {
  toasts: Array<Toast> = [];
  defaultTimeout = 2500;

  public showToast(message: string, variant: Variant, timeout?: number): void {
    toastId += 1

    const toast: Toast = {
      id: toastId,
      message,
      timeout: timeout || this.defaultTimeout,
      index: this.toasts.length,
      variant
    }
    this.toasts.push(toast)

    setTimeout(
      () => {
        this.removeToast(toast.id)
      },
      toast.timeout,
      toast
    )
  }

  public removeToast(id: number): void {
    this.toasts = this.toasts.filter((toast: Toast) => toast.id !== id)
  }
}
