
import { Options, Vue } from 'vue-class-component'
import MswModal from '@/components/Modal.vue'
import { ExclamationIcon } from '@heroicons/vue/outline'

@Options({
  props: {
    title: String,
    message: String
  },
  emits: ['cancel', 'confirm'],
  components: { MswModal, ExclamationIcon }
})
export default class MswConfirmModal extends Vue {
  title = '<modal title missing>'
  message = '<modal message missing>'
}
