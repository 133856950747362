
import { Options, Vue } from 'vue-class-component'
import MswModal from '@/components/Modal.vue'
import { ExclamationCircleIcon } from '@heroicons/vue/outline'

@Options({
  props: {
    title: String,
    message: String,
    buttonText: String,
    hideButtons: Boolean
  },
  emits: ['dismissed'],
  components: { MswModal, ExclamationCircleIcon }
})
export default class MswErrorModal extends Vue {
  title = '<modal title missing>'
  message = '<modal message missing>'
  buttonText = '<button text missing>'
  hideButtons = false
}
