
import { Options, Vue } from 'vue-class-component'
import MswModal from '@/components/Modal.vue'
import { XIcon } from '@heroicons/vue/outline'
import SpinnerIcon from '@/components/SpinnerIcon.vue'

@Options({
  props: {
    firebaseAuthUiId: String,
    title: String,
    loginInProgress: Boolean
  },
  emits: ['dismissed'],
  components: { MswModal, XIcon, SpinnerIcon }
})
export default class MswLoginModal extends Vue {
    firebaseAuthUiId = ''
    title: string | null = null
    loginInProgress = false
}
